class AjaxPress {
    instance = null;
    #state = {};
    #callbacks = {
        clicked: [],
        fetched: [],
        changed: [],
        html: null,
    }
    // on
    on(event, callback) {
        if (typeof this.#callbacks[event] !== 'object') return;

        if (this.#callbacks[event]) {
            this.#callbacks[event].push(callback);
        }
    }

    // off
    off(event, callback) {
        if (typeof this.#callbacks[event] !== 'object') return;

        if (this.#callbacks[event]) {
            this.#callbacks[event] = this.#callbacks[event].filter(cb => cb !== callback);
        }
    }

    clicked(callback) {
        this.on('clicked', callback);
    }

    fetched(callback) {
        this.on('fetched', callback);
    }

    changed(callback) {
        this.on('changed', callback);
    }

    // html
    html(callback) {
        if (typeof callback !== 'object') return;

        this.#callbacks.html = callback;
    }

    // trigger
    trigger(event, data) {
        if (this.#callbacks[event]) {
            this.#callbacks[event].forEach(callback => {
                callback(data);
            })
        }
    }

    get url() { return this.#state.url || this.currentURL };
    get html() {
        if (this.#callbacks.html) {
            return this.#callbacks.html(this.#state.html);
        }

        return this.#state.html || '';
    };
    get target() { return ajaxpress.options.target || 'main' };

    static getInstance() {
        if (!this.instance) {
            this.instance = new AjaxPress();
        }
        return this.instance;
    }

    constructor() {
        this.registerEvents();
    }


    isSameOrigin(url) {
        try {
            const a = new URL(url);
            const b = new URL(window.location.href);
            return a.origin === b.origin;
        } catch (e) {
            return false
        }

    }

    isSameURL(url) {
        // match url, even they have different hash and query string
        const a = new URL(url);
        const b = new URL(window.location.href);
        return a.origin === b.origin && a.pathname === b.pathname;
    }

    get currentURL() {
        return window.location.href;
    }

    registerEvents() {
        if (ajaxpress.options.navigation) {
            // on click any a 
            document.addEventListener('click', this.processHyperlink.bind(this));

            // on popstate
            window.addEventListener('popstate', this.popState.bind(this));
        }

        if (ajaxpress.options.comment || ajaxpress.options.search) {

            // on submit form
            document.addEventListener('submit', this.processForm.bind(this));
        }

        if (ajaxpress.options.search && ajaxpress.options.live_search) {
            // on keypress on type="search"
            document.addEventListener('keypress', this.processSearch.bind(this));
        }

    }

    async processSearch(e) {
        if (e.target.type !== 'search') return;

        this.#state.url = e.target.form.action + '?s=' + e.target.value;


        if (this.#state.throttle) return;

        this.#state.throttle = setTimeout(() => {
            clearInterval(this.#state.throttle);
            this.#state.throttle = null;
        }, 500);

        this.#state.push = true;

        await this.load();

        // focus 
        e.target.focus();
    }

    isURLLoadable(url) {

        // Bail, if options.navigation is false
        if (!ajaxpress.options.navigation) return;

        // Bail if the URL is not from the same origin  
        if (!this.isSameOrigin(url)) return;

        // Bail if the URL is admin url, login url, or logout url
        if (url.includes('wp-admin') || url.includes('wp-login') || url.includes('wp-login')) return;

        // Bail if the URL is a file
        const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mp3', 'mp4', 'mov', 'avi', 'wmv', 'jpg', 'jpeg', 'png', 'gif', 'svg'];
        if (fileExtensions.some(ext => url.includes(ext))) return;

        // Bail if the URL is a mailto link
        if (url.includes('mailto:')) return;

        // Bail if the URL is a tel link
        if (url.includes('tel:')) return;

        // Bail if the URL is a same page link with a hash 
        if (this.isSameURL(url) && url.includes('#')) return;

        return true;
    }

    async processHyperlink(e) {

        // bail if not a tag 
        if (e.target.tagName !== 'A') return;

        if (!this.isURLLoadable(e.target.href)) return;

        e.preventDefault();

        this.#state.url = e.target.href;

        this.#state.push = true;

        await this.load()
    }

    async popState(e) {
        if (!e.state.url || !this.isURLLoadable(e.state.url)) return;

        this.#state.url = e.state.url;
        this.#state.push = false;

        await this.load()
    }

    async fetch() {
        const response = await fetch(this.url);
        const html = await response.text();
        this.#state.html = html || '';
    }
    async render() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.html, 'text/html');

        // replace title 
        const title = doc.querySelector('title').innerHTML;
        document.title = title;

        // replace content
        const content = doc.querySelector(this.target).innerHTML || doc.querySelector('body').innerHTML;
        const container = document.querySelector(this.target) || document.querySelector('body');
        if (container) container.innerHTML = content;

        // push state
        if (this.#state.push) {
            const state = { title, content, url: this.url };
            window.history.pushState(state, title, this.url);
        }

        // scroll to top smooth
        if (this.#state.noScroll) return;

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    async load() {
        await this.fetch();

        // before change
        this.trigger('fetched', {
            url: this.url,
            html: this.html
        });
        await this.render();

        // after change
        this.trigger('changed', {
            url: this.url,
            html: this.html
        });
    }

    async processForm(e) {

        const form = e.target;

        const forms = '.wp-block-search,.search-form,.comment-form,form[role="search"], #commentform';

        if (!form.matches(forms)) {
            return true;
        }

        // check if search form and options.search is true 
        if (form.matches('.wp-block-search,.search-form,form[role="search"]') && !ajaxpress.options.search) {
            return true;
        }

        // check if comment form and options.comments is true
        if (form.matches('.comment-form,#commentform') && !ajaxpress.options.comment) {
            return true;
        }
        e.preventDefault();
        this.submitForm(form)
    }

    async submitForm(form) {
        const action = form.action
        const method = form.method;

        // Get the form data
        const formData = method === 'get' ? new URLSearchParams(new FormData(form)).toString() : new FormData(form);
        const finalAction = method === 'get' ? `${action}?${formData}` : action;

        if (method.toLowerCase() === 'get') {
            // Fetch the page
            this.#state.url = finalAction;
            // before change
            this.#state.push = true;
            await this.load()
            this.#state.push = false;

        } else {
            // Submit the form using ajax
            const response = await fetch(finalAction, {
                method,
                body: formData,
            });

            this.#state.noScroll = true;

            await this.load()

            // scroll to nearest comment
            if (form.classList.contains('comment-form')) {
                const comments = document.querySelectorAll('.comment');
                const lastComment = comments[comments.length - 1];
                if (lastComment) {
                    lastComment.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }

            this.#state.noScroll = false;
        }


    }
}

export default AjaxPress.getInstance();